import { useEffect, useState } from 'react';
import * as language from '../constants/languages';
import ProductDetailsBrochure from './ProductDetailsBrochure';
import NoImage from "../assets/svg/hideimage.svg"

export default function ProductDetailsSection({ productData, imagePressed }) {
    const [currencySymbol, setcurrencySymbol] = useState()

    useEffect(() => {
        getCompanyInfo()
    }, [])

    //get sotre name
    async function getCompanyInfo() {

        const value = localStorage.getItem('companyInfo')

        if (value === null || value === undefined) {
            const companyInfo = null;
            setcurrencySymbol("")

        } else {
            const companyInfo = JSON.parse(value);
            setcurrencySymbol(companyInfo.currencySymbol)

        }

    }

    return (
        <div className='product-details-section'>
            <div style={{ paddingLeft: '1rem' }}>
                <p className='textCommonStyledDetails'>{productData.productName}</p>
                {
                    productData.regionalLanguage != (null || "") &&
                    <p style={{ fontFamily: 'Poppins-Regular', fontSize: 14 }}>{productData.regionalLanguage}</p>
                }
                {
                    productData.isStockAvailable == false ?
                        <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.red }}>{language.outStockLabel}</p>
                        :
                        <p style={{ fontFamily: 'BeVietnamPro-Regular', fontSize: 12, color: language.green }}>{language.inStockLabel}</p>
                }

                <div style={{ marginTop: 15 }}>
                    {
                        productData.oRate == null ?
                            <div>
                                {
                                    productData.sRate < (productData.mrp == null ? productData.sRate : productData.mrp) ?
                                        <div style={{ alignItems: 'center', marginBottom: 10, paddingTop: 10 }}>
                                            <p style={{ marginHorizontal: 0, fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through' }}>
                                                {currencySymbol} {(productData.mrp * productData.incrementValue).toFixed(2)}
                                            </p>
                                            <p style={{ marginHorizontal: 0, fontFamily: 'Montserrat-SemiBold', fontSize: 18, color: '#FF0000' }}>
                                                {currencySymbol} {(productData.sRate * productData.incrementValue).toFixed(2)}
                                            </p>
                                            <p style={{ marginHorizontal: 0, fontFamily: 'Gilroy-Regular' }}>
                                                <p style={{ fontFamily: 'Gilroy' }}> ({productData.savedPercentage}% off) </p>
                                            </p>
                                        </div>
                                        :

                                        <p style={{ marginHorizontal: 0, fontFamily: 'Montserrat-SemiBold', fontSize: 18, color: '#FF0000' }}>
                                            {currencySymbol} {(productData.sRate * productData.incrementValue).toFixed(2)}
                                        </p>
                                }
                            </div>
                            :
                            <div>
                                {
                                    productData.oRate < (productData.mrp == null ? productData.sRate : productData.mrp) ?
                                        <div>
                                            <p style={{ fontFamily: 'Montserrat-Regular', textDecorationLine: 'line-through' }}>
                                                {currencySymbol} {((productData.mrp == null ? productData.sRate : productData.mrp) * productData.incrementValue).toFixed(2)}
                                            </p>
                                            <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 18, color: '#f70505' }}>
                                                {currencySymbol} {(productData.oRate * productData.incrementValue).toFixed(2)}
                                            </p>

                                            <p style={{ fontFamily: 'Montserrat-Regular' }}> ({productData.savedPercentage}% off) </p>


                                        </div>
                                        :

                                        <p style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 18, color: '#f70505' }}>
                                            {currencySymbol} {(productData.oRate * productData.incrementValue).toFixed(2)}

                                        </p>
                                }
                            </div>
                    }
                </div>
            </div>
            <div style={{ marginTop: 10 }}>
                {
                    productData.tblProductImages != null ?
                        <ProductDetailsBrochure
                            imagePressed={(item) => { imagePressed(item) }}
                            data={productData.tblProductImages == null ? [] : productData.tblProductImages}
                            stock={productData.isStockAvailable}
                        />
                        :
                        <div style={{ justifyContent: 'center', alignItems: 'center', flex: 1,  display: 'flex' }}>
                            <img src={NoImage} className='product-details-brochure-image' height='auto' />
                            <p style={{ position: "absolute", fontFamily: 'BeVietnamPro-Regular', color: "#bbbdbf" }}>No Image</p>
                        </div>
                }
            </div>
        </div>
    )


}